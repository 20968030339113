<app-header></app-header>
<div class="hero-section section pt-0">
    <div class="container pt-5">
    <div class="container h-100 text-white">
        <p class="display-6 fw-bold text-center an-1" style="padding-top: 9em;" >Get In Touch With Us</p>
      </div>
    </div>
   
  </div>


  
<div class="blog-section section container">
    <div class="row">
        <div class="col-sm-8 offset-lg-2">
            <h4 >Contact Us</h4>
            <div class="row"> 
              <div class="col-md-6">
                  <div class="row">
                      <div class="col-sm-12">
                          <mat-form-field appearance="outline">
                              <mat-label>Name</mat-label>
                              <input matInput placeholder="Name">
                          </mat-form-field>
                      </div>
                      <div class="col-sm-6">
                          <mat-form-field appearance="outline">
                              <mat-label>Country Code</mat-label>
                              <input matInput placeholder="Contry Code">
                          </mat-form-field>
                      </div>
                      <div class="col-sm-6">
                          <mat-form-field appearance="outline">
                              <mat-label>Phone </mat-label>
                              <input matInput placeholder="Phone">
                          </mat-form-field>
                      </div>
                      <div class="col-sm-12">
                          <mat-form-field appearance="outline">
                              <mat-label>Email</mat-label>
                              <input matInput placeholder="Email">
                          </mat-form-field>
                      </div>
                      <div class="col-sm-12">
                          <mat-form-field appearance="outline">
                              <mat-label>Subject</mat-label>
                              <input matInput placeholder="Subject">
                          </mat-form-field>
                      </div>
      
                      <div class="col-sm-12">
                          <mat-form-field appearance="outline">
                              <mat-label>Message</mat-label>
                              <textarea matInput placeholder="Message"></textarea>
                          </mat-form-field>
                      </div>
                      <div class="col-sm-12">
                          <button class="btn btn-success"> Send Message</button>
                      </div>
      
                  </div>
              </div>
      
              <div class="col-sm-6">
                <h4>Head Office</h4>
                <div class="row">
                    <div class="col-sm-12">
                        <ul style="list-style: none;">
                            <li>Royal Liner</li>
                            <li>Mombasa - Mwembe Tayari, at the former Spanish Coach Booking Office</li>
                            <li>Email Us : <b>royalcoachexpressltd@gmail.com</b> </li>
                            <li>Phone : <b>+254710300688 /+254710741641</b></li>
                            <li>Tel : <b>+254710300688 </b></li>
                        </ul>
                      
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
   
    
  </div>
  <app-footer></app-footer>