<nav class="navbar navbar-expand-lg bg-light rounded sticky-top shadow p-2 p-lg-0">
    <div class="container px-0 ps-3">
      <a class="navbar-brand" href="#">
        <img src="assets/img/logo.png" height="60px" width="120px" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar">
        <span class="navbar-toggler-icon" style="color:black;"></span>
      </button>
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
           aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            <img src="assets/img/logo.png" height="50px" width="130px" alt="">
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul
            class="navbar-nav ms-auto my-2 my-lg-0 align-items-start align-items-lg-center navbar-nav-scroll pe-3">
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active"  [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" >HOME</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active"  [routerLink]="['/about']" [routerLinkActiveOptions]="{ exact: true }"  routerLink="/about">ABOUT US</a>
            </li>
            
            <li class="nav-item">
              <a class="nav-link"  routerLinkActive="active"  [routerLink]="['/contact']" [routerLinkActiveOptions]="{ exact: true }"  routerLink="/contact">CONTACT US</a>
            </li>
            <li class="nav-item">
              <a class="nav-link"  routerLinkActive="active"  [routerLink]="['/print-ticket']" [routerLinkActiveOptions]="{ exact: true }"  routerLink="/print-ticket" >PRINT TICKET</a>
            </li>
            <li class="nav-item" (click)="loginModal.show()" *ngIf="!authenticated">
              <a class="nav-link" >SIGN IN/REGISTER</a>
            </li>
            <li class="nav-item dropdown" *ngIf="authenticated">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                 aria-expanded="false">
                 <i class="fa-solid fa-user-tie"></i> {{user.name}}
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" routerLink="/profile">PROFILE</a></li>
                <li><a class="dropdown-item" (click)="logout()" >SIGN OUT</a></li>

              </ul>
            </li>

            <li class="nav-item">
              <a class="nav-link"  routerLinkActive="active"  [routerLink]="['/privacy']" [routerLinkActiveOptions]="{ exact: true }"  routerLink="/contact">PRIVACY</a>
            </li>
          </ul>
          <!-- <div class="bg-dark text-white p-3 d-flex align-items-center gap-2 rounded-end d-none d-lg-flex">
            <i class="fas fa-phone fa-lg"></i>
            <div>
              <p class="mb-0">Contact Us Now</p>
              <p class="mb-0">+254794837330</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </nav>



  <div class="modal fade" bsModal #loginModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-static-name" class="modal-title pull-left">JOIN ROYAL LINER</h5>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="loginModal.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <mat-radio-group [(ngModel)]="type"  >
          <div class="row">
           <div class="col-sm-3 col-6">
              <mat-radio-button selected value="1">SIGN IN </mat-radio-button>
           </div>
           <div class="col-sm-3 col-6">
              <mat-radio-button value="2">REGISTER</mat-radio-button> 
          </div>

          </div>
      </mat-radio-group>
      <div class="row" style="margin-top: 10px;" *ngIf="type=='1'">
      <div class="col-sm-12">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="row">
          <div class="col-sm-4">
            <mat-form-field appearance="outline">
              <mat-label>Code</mat-label>
              <mat-select formControlName="country_code" >
                <mat-option *ngFor="let code of codes" [value]="code">
                  {{code}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-8">
            <mat-form-field appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput  formControlName="username"    placeholder="Username">
          </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput type="password"  formControlName="password"    placeholder="Password">
          </mat-form-field>
          </div>
          <div class="col-sm-12">
            <button class="btn view-seats" [disabled]="loginForm.invalid" style="float: right;">Sign In</button>
          </div>
        </div>
        </form>
      </div>
      </div>
      <div class="row" style="margin-top: 10px;" *ngIf="type=='2'">
        <div class="col-sm-12">
          <form [formGroup]="registerForm" (ngSubmit)="register()">
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput   formControlName="name"    placeholder="Name">
            </mat-form-field>
            </div>
            <div class="col-sm-4">
              <mat-form-field appearance="outline">
                <mat-label>Code</mat-label>
                <mat-select formControlName="country_code" >
                  <mat-option *ngFor="let code of codes" [value]="code">
                    {{code}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-8">
              <mat-form-field appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput  formControlName="phone"    placeholder="Phone">
            </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field appearance="outline">
                <mat-label>Date of Birth</mat-label>
                <input matInput  formControlName="dob" [max]="max_date" [matDatepicker]="picker1" placeholder="Date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field appearance="outline">
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender" >
                  <mat-option  value="Female">
                   Female
                  </mat-option>
                  <mat-option  value="Male">
                    Male
                   </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-sm-7">
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="email"  formControlName="email"    placeholder="Email">
            </mat-form-field>
            </div>

            <div class="col-sm-5">
              <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password"  formControlName="password"    placeholder="Password">
            </mat-form-field>
            </div>
            <div class="col-sm-12">
              <button class="btn btn-success" style="float: right;" >Register</button>
            </div>
          </div>
          </form>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #otpModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left">Enter OTP</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="otpModal.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <form [formGroup]="otpForm" (ngSubmit)="onOtp()">
            <div class="row">
              <div class="col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Enter Otp</mat-label>
                  <input matInput  type="password" formControlName="code"    placeholder="Otp Code">
              </mat-form-field>
              </div>
              
              <div class="col-sm-12">
                <button class="btn btn-primary" [disabled]="otpForm.invalid" style="float: right;" >Verify </button>
              </div>
            </div>
            </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#ffe716" type = "pacman" [fullScreen] = "true"><p style="color: white" >Please wait... </p></ngx-spinner>
